import {
  AfterViewChecked,
  AfterViewInit, ChangeDetectorRef,
  Component,
  ElementRef,
  Input, OnChanges,
  QueryList, SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {animate, style, transition, trigger} from "@angular/animations";
import {scrollToBottom} from "../../../utils/scroll.util";
import {MessageBubbleComponent} from "../message-bubble/message-bubble.component";
import { Message } from "../../../models/message";
import {AsyncPipe, NgFor} from '@angular/common';

@Component({
  selector: 'app-chat-messages',
  standalone: true,
  imports: [ MessageBubbleComponent],
  animations: [
    trigger('fadeSlide', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(10px)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ])
    ])
  ],
  templateUrl: './chat-messages.component.html',
  styleUrl: './chat-messages.component.scss'
})
export class ChatMessagesComponent implements AfterViewChecked {
  @ViewChild('messagesContainer') private messagesContainer!: ElementRef<HTMLElement>;
  @Input() messages: Message[] | null = [];

  ngAfterViewChecked() {
    scrollToBottom(this.messagesContainer.nativeElement);
  }
}
