<div class="p-4 bg-white border-t border-gray-200">
  <form (ngSubmit)="sendMessage()" class="flex gap-2">
    <input
      type="text"
      [(ngModel)]="messageText"
      name="messageText"
      placeholder="Type a message..."
      class="flex-1 px-4 py-3 border border-gray-200 rounded-full focus:outline-none focus:border-blue-500"
    />
    <button
      type="submit"
      [disabled]="!messageText.trim()"
      style="background: linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);"
      class="px-6 py-3  text-white rounded-full disabled:opacity-50 disabled:cursor-not-allowed">
      Send
    </button>
  </form>
</div>
