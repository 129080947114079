import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {forkJoin, Observable} from 'rxjs';
import {UserService} from "./user.service";
import {getAuth, signInWithCustomToken, signOut} from "@angular/fire/auth";
import {APP_ENVIRONMENT} from "./environment.service";

interface IAuth {
  email: string;
  password: string;
  clientId: string;
  scopes: Array<string>;
  refreshTokenValidDays: number;
  fetchUsedApps: boolean;
}

class AuthFirebase implements IAuth {
  email!: string;
  password!: string;
  facebookAccessToken?: string;
  appleIdAccessToken?: string;
  clientId: string = 'leaf.app';
  scopes: Array<string> = ['firebase.api.rw'];
  refreshTokenValidDays: number = 10;
  fetchUsedApps: boolean = false;
}

class Auth implements IAuth {
  email!: string;
  password!: string;
  facebookAccessToken?: string;
  appleIdAccessToken?: string;
  clientId: string = 'web.app';
  scopes: Array<string> = ['stats-ms.api.rw', 'leaf.api.rw'];
  refreshTokenValidDays: number = 10;
  fetchUsedApps: boolean = false;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  get redirectURl(): string {
    return localStorage.getItem('redirectUrl') || '';
  }

  set redirectURl(value: string) {
    localStorage.setItem('redirectUrl', value);
  }
  public ENDPOINT: string = this.environment.authUrl + '/token/get';
  public ENDPOINT_ACCESS_TOKEN: string = this.environment.authUrl + '/access-code';
  public ENDPOINT_TOKEN_REFRESH: string = this.environment.authUrl + '/token/refresh';
  public ENDPOINT_SEND_PASSWORD_RESET_EMAIL: string = this.environment.authUrl + '/users/authenticated/password/reset/sendemail';
  public ENDPOINT_PASSWORD_RESET: string = this.environment.authUrl + '/users/authenticated/password/reset';

  public static readonly ACCESS_TOKEN: string = 'X-Access-Token';
  public static readonly REFRESH_TOKEN: string = 'X-Refresh-Token';

  public static readonly BELLABEAT_ACCESS_TOKEN: string = 'Bellabeat-X-Access-Token';
  public static readonly BELLABEAT_REFRESH_TOKEN: string = 'Bellabeat-X-Refresh-Token';

  public static readonly FIREBASE_ACCESS_TOKEN: string = 'Firebase-X-Access-Token';
  public static readonly FIREBASE_REFRESH_TOKEN: string = 'Firebase-X-Refresh-Token';

 private auth;
  constructor(
    public router: Router,
    private http: HttpClient,
    @Inject(APP_ENVIRONMENT) private environment: any,
    private userService: UserService,
  ) {
    this.auth = getAuth();
  }

  get isLoggedIn(): boolean {
    return !!JSON.parse(localStorage.getItem('user'));
  }

  signIn(email: string, password: string): Observable<any> {
    const firebase = new AuthFirebase();
    firebase.email = email;
    firebase.password = password;
    return this.sign(firebase, this.generateOptions());
  }
  //
  // signInSocial(accessToken: string, type: string): Observable<any> {
  //   this.clearLocalStorage();
  //   const options = this.generateOptions();
  //   const auth = new AuthFirebase();
  //
  //   if (type === 'facebook') {
  //     auth.facebookAccessToken = accessToken;
  //   } else {
  //     auth.appleIdAccessToken = accessToken;
  //   }
  //
  //   return this.http.post<any>(this.environment.socialLoginUrl + '/' + type + '/login', auth, options).pipe(
  //     switchMap((response) => {
  //       console.log(type + ' login: ', response.toString());
  //       this.saveFirebaseToken(response);
  //       const initAuth = this.initAuth();
  //       return forkJoin(initAuth).pipe(
  //         switchMap(() => {
  //           const bbAuth = new Auth();
  //           if (type === 'facebook') {
  //             bbAuth.facebookAccessToken = accessToken;
  //           } else {
  //             bbAuth.appleIdAccessToken = accessToken;
  //           }
  //
  //           return this.http.post<any>(this.environment.socialLoginUrl + '/' + type + '/login', bbAuth, this.generateOptions()).pipe(
  //             switchMap((responseBBAuth: any) => {
  //               this.saveBellabeatToken(responseBBAuth);
  //               return this.userService.getProfileData();
  //             }),
  //           );
  //         }),
  //       );
  //     }));
  // }

  signWithRefreshToken(externalToken: string): Observable<any> {
    const refreshToken = externalToken ? externalToken : localStorage.getItem(AuthService.BELLABEAT_REFRESH_TOKEN);
    return this.refreshToken(externalToken).pipe(
      switchMap((response) => {
        // After token is refreshed sign again with access token so scope can be refreshed
        const accessToken = response.headers.get(AuthService.ACCESS_TOKEN);
        return this.sign(new AuthFirebase(),
          this.generateOptions('Authorization', 'bearer ' + accessToken)).pipe(
          catchError((err, caught) => {
            return err;
          })
        );
      })
    );
  }

  /**
   * Refreshes token for the user with given token
   * @param token - which should be refreshed
   */
  public refreshToken(token: string): Observable<any> {
    const options = this.generateOptions(AuthService.REFRESH_TOKEN, token);

    return this.http.post(this.ENDPOINT_TOKEN_REFRESH, null, options).pipe(
      tap((res: any) => this.saveBellabeatToken(res)),
      catchError((err) => {
        this.signOut();
        throw Error(err.error.msg);
      })
    );
  }

  getAccessToken(): Observable<any> {
    return this.http.get(this.ENDPOINT_ACCESS_TOKEN, {observe: 'response' as 'body'});
  }


  signOut(): void {
    this.http.post(`${this.environment.authUrl}/token/logout`, {})
      .subscribe(() => {
        try {
          // @ts-ignore
          FB.getLoginStatus((response: any) => {
            if (response.status === 'connected') {
              // @ts-ignore
              FB.logout((data: any) => {
                console.log('User is logged out from FB.');
              });
            }
          });

          // FB.logout((response: any) => {
          //   console.log(response);
          //   console.log('User is logged out from FB.');
          // });
        } catch (e) {
          console.error('Logout: FB api not initialised');
        }

      });

    this.clearLocalStorage();
    signOut(this.auth).then(() => {
      this.router.navigate(['']).then(() => {
        window.location.reload();
      });
    });
  }


  clearLocalStorage(): void {
    localStorage.removeItem('user');
    // localStorage.removeItem(AuthService.FB_SESSION_ID);
    localStorage.removeItem(AuthService.FIREBASE_ACCESS_TOKEN);
    localStorage.removeItem(AuthService.FIREBASE_REFRESH_TOKEN);
    localStorage.removeItem(AuthService.BELLABEAT_ACCESS_TOKEN);
    localStorage.removeItem(AuthService.BELLABEAT_REFRESH_TOKEN);
  }

  /**
   *  Auth to Bellabeat UAA service
   *
   */
  private sign(auth: IAuth, options: any): Observable<any> {
    return this.http.post<any>(this.ENDPOINT, auth, options).pipe(
      switchMap((response) => {
        console.log('Login: ' + response.toString());
        const res: any = response;
        this.saveFirebaseToken(response);
        const initAuth = this.initAuth();
        return forkJoin(initAuth).pipe(
          switchMap(() => {
            const bbAuth = new Auth();
            bbAuth.email = auth.email;
            bbAuth.password = auth.password;
            return this.http.post<any>(this.ENDPOINT, bbAuth, this.generateOptions()).pipe(
              switchMap((responseBBAuth: any) => {
                this.saveBellabeatToken(responseBBAuth);
                return this.userService.getProfileData();
              }),
              catchError((err, caught) => {
                return err;
              })
            );
          }), catchError((err, caught) => {
            return err;
          })
        );
      })
    );
  }

  private initAuth(): Array<any> {
    // const persistence = setPersistence(this.auth, browserLocalPersistence)
    //   .then(() => console.log('Set persistence complete'));
    const firebaseAuthLogin = signInWithCustomToken(this.auth, localStorage.getItem(AuthService.FIREBASE_ACCESS_TOKEN ) || '')
      .then(
        (response) => console.log('Firebase Auth complete'),
        (error) => console.log('Failed to complete Firebase Auth')
      );
    return [ firebaseAuthLogin];
  }

  /**
   * Retrieves user details from token
   *
   * @param token JWT token
   */
  private getTokenClaims(token: string): any {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }

  /**
   * Generate header options, token value if needed. supported login with x-refresh-token, x-auth-token and Authorization
   *
   * @param tokenType Optional
   * @param tokenValue Optional
   */
  private generateOptions(tokenType?: string, tokenValue?: string): any {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Access-Control-Allow-Origin', '*');
    headers = headers.set('Access-Control-Allow-Headers', 'Origin, Authorization, Content-Type');
    if (tokenType && tokenValue) {
      headers = headers.set(tokenType, tokenValue);
    }
    return { headers, observe: 'response' as 'body' };
  }

  private saveBellabeatToken(res: any): void {
    const token = res.headers.get(AuthService.ACCESS_TOKEN);
    const refreshToken = res.headers.get(AuthService.REFRESH_TOKEN);

    localStorage.setItem(AuthService.BELLABEAT_ACCESS_TOKEN, token);
    localStorage.setItem(AuthService.BELLABEAT_REFRESH_TOKEN, refreshToken);
  }

  private saveFirebaseToken(res: any): void {
    const token = res.headers.get(AuthService.ACCESS_TOKEN);
    const refreshToken = res.headers.get(AuthService.REFRESH_TOKEN);
    const claims = this.getTokenClaims(token);
    this.userService.setBasicUserInfo(res.body.id, res.body.name, res.body.email, claims?.claims?.ROLE_DOCTOR);

    localStorage.setItem(AuthService.FIREBASE_ACCESS_TOKEN, token);
    localStorage.setItem(AuthService.FIREBASE_REFRESH_TOKEN, refreshToken);

    claims.token = token;
  }

  sendPasswordResetMail(email: string, redirect?: string): Observable<any> {
    const data: any = {email};
    if (redirect) {
      data.redirect = redirect;
    }
    return this.http.post(this.ENDPOINT_SEND_PASSWORD_RESET_EMAIL, data);
  }

  passwordReset(token: string, password: string): Observable<any> {
    const data = {token, 'newPassword': password};
    return this.http.post(this.ENDPOINT_PASSWORD_RESET, data);
  }

}
