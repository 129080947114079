<div class="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
  <div class="max-w-4xl mx-auto">
    <div class="text-center mb-8">
      <h1 class="text-3xl font-bold text-gray-900">License Agreement</h1>
      <p class="mt-2 text-sm text-gray-600">Please read and accept our terms before continuing</p>
    </div>

    <div class="bg-white shadow rounded-lg">
      <div class="p-6 space-y-6 max-h-[60vh] overflow-y-auto text-gray-700">
        <!-- Privacy Consent Section -->
        <div class="prose prose-sm">
          <h2 class="text-xl font-semibold text-gray-900 mb-4">Privacy Consent</h2>
          <p class="mb-4">
            By choosing to use Bellabeat Health Assistant and submitting content, Personal Data, or other information to Bellabeat Health Assistant, you confirm that you have read and agree to this privacy consent and supplemental privacy notice, as well as our Privacy Policy and Terms of Service. Personal Data, as used in our Privacy Policy and this Disclaimer, means any information that directly or indirectly relates to an identified or identifiable individual; it is synonymous with "personal information" or "user data" and includes Sensitive Personal Data. If you do not agree to this Privacy Consent and supplemental privacy notice, then you should not use the Bellabeat Health Assistant service.
          </p>

          <!-- Medical Disclaimer Section -->
          <h2 class="text-xl font-semibold text-gray-900 mb-4">Medical Disclaimer</h2>
          <p class="mb-4">
            The information, responses, and results provided by Bellabeat Health Assistant service are strictly for general informational purposes only and are not intended to replace medical or other professional advice, treatment, or diagnosis. Always seek the advice of a qualified health and medical provider with any questions you have regarding a medical condition. Never disregard professional medical advice, or delay in seeking it, because of something you read from Bellabeat Health Assistant service. Bellabeat Health Assistant service makes no representation or warranty as to the accuracy, completeness, or reliability of the information provided by Bellabeat Health Assistant service. Bellabeat expressly disclaims any and all liability, express or implied, for omissions from or errors arising from use of Bellabeat Health Assistant.
          </p>

          <!-- AI Disclosure Section -->
          <h2 class="text-xl font-semibold text-gray-900 mb-4">AI System Disclosure</h2>
          <p class="mb-4">
            To provide information, responses, and results to you, Bellabeat Health Assistant service uses artificial intelligence and machine learning systems provided by OpenAI, L.L.C. ("OpenAI"). Those systems are rapidly evolving, and given the probabilistic nature of machine learning, use of this system may, in some situations, result in output that is incorrect, incomplete, or does not accurately reflect real people, places, situations, or facts. You should evaluate the accuracy of any output as appropriate for your use case, including by using a human review of the output. It is also important to note that even where there are no inaccuracies, the information provided by Bellabeat Health Assistant service is based on general information and cannot take into account your specific circumstances, health history, or other relevant factors. Therefore, we strongly recommend that you consult with a qualified health provider before making any decisions regarding your or any other person's health based on information generated by the Bellabeat Health Assistant service.
          </p>

          <!-- Data Processing Notice -->
          <h2 class="text-xl font-semibold text-gray-900 mb-4">Data Processing Notice</h2>
          <p>
            You should be aware that any information, including communications, content, Personal Data, including Sensitive Personal Data, you provide through, or that is collected by Bellabeat Health Assistant service will be transmitted to OpenAI, our artificial intelligence service provider, for processing in order to provide our services to you. Bellabeat may record, disclose, transmit, process, and use any such information, including communications, to provide our services as further described in our Privacy Policy and this supplemental privacy notice. OpenAI retains submitted data and information for the period of 30 days by default by the design of OpenAI and may record, process, transmit, and use any such information, including communications, in conjunction with providing our services to you.
          </p>
        </div>
      </div>

      <!-- Action Buttons -->
      <div class="px-6 py-4 bg-gray-50 rounded-b-lg border-t border-gray-200">
        <div class="flex justify-end space-x-4">
          <button
            (click)="decline()"
            class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
            Decline
          </button>
          <button
            (click)="accept()"
            class="px-4 py-2 text-sm font-medium text-white rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#dd3675]"
            style="background: linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593)">
            Accept
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
