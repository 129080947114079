import {Component, Input} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../../services/auth.service";
import {Router} from "@angular/router";
import {UserService} from "../../../services/user.service";

@Component({
  selector: 'lib-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  loginForm: FormGroup;
  loginError: any;
  isLoading: boolean;
  @Input() drLogin: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private auth:AuthService,
              private userService: UserService,
              private router: Router) {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  ngOnInit() {
    // Any initialization logic can go here
  }

  onSubmit() {
    console.log(this.loginForm.errors)
    if (this.loginForm.valid) {
      let auth$ = this.auth.signIn(this.loginForm.controls['email'].value, this.loginForm.controls['password'].value);
      auth$.subscribe({
        next: (v) => console.log(v),
          error: (e) => {
          this.loginError = e.error.msg;
          console.error(e)
          },
          complete: () => {
            if(this.drLogin && !this.userService.user.isDoctor){
              this.loginError = 'You do not have permission to access this application';
            } else {
              this.router.navigate(['pages'])
            }
          }

      })


    }
  }

  isFieldInvalid(password: string) {
    return false;
  }
}
