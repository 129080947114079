import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {APP_ENVIRONMENT} from "./environment.service";
import {
  addDoc,
  collection,
  doc,
  Firestore,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query, Unsubscribe
} from "@angular/fire/firestore";

import {UserService} from "./user.service";
import {Message} from "../models/message";
import {get} from "@angular/fire/database";

@Injectable({
  providedIn: 'root'
})
export class ClientChatService {
  private chatMessagesSubject: BehaviorSubject<Message[]> = new BehaviorSubject<Message[]>([]);
  public chatMessages$: Observable<Message[]> = this.chatMessagesSubject.asObservable();
  private unsubscribe: Unsubscribe | null = null;
  constructor(
    @Inject(APP_ENVIRONMENT) private environment: any,
    private firestore: Firestore,
    private userService: UserService
  ) {
    this.listenToChatMessages();
  }

  send(text: string){
    //v0/data/user/user5801e401d19d4b4fa39210ed687abd79/bellaChat/chatHistory/chatMain
    const message: Message = this.newMessage(text)
    return Promise.all([addDoc(
      collection(this.firestore,
        'v0',
        'data',
        'user',
        this.userService.user.id,
        'bellaChat',
        'chatHistory',
        'chatDoctor'),
      Object.assign({}, message)
    ), Promise.resolve(message) ])
  }

  getChat(){
    return this.chatMessages$;
  }

  private listenToChatMessages(): void {
    if (this.unsubscribe) {
      this.unsubscribe(); // Unsubscribe from the previous listener if it exists
    }

    const chatCollection = collection(
      this.firestore,
      'v0',
      'data',
      'user',
      this.userService.user.id,
      'bellaChat',
      'chatHistory',
      'chatDoctor'
    );

    const q = query(chatCollection, orderBy('date', 'asc'));

    this.unsubscribe = onSnapshot(q, (snapshot) => {
      const messages: Message[] = [];
      snapshot.docChanges().forEach((change) => {
        if (change.type === 'added') {
          const messageData = change.doc.data();
          const message: Message = {
            id: 0,
            userId: '',
            message: messageData['message'],
            source: messageData['source'],
            date: messageData['date'].toDate(), // Convert Timestamp to Date
            application: messageData['application'],
            end: messageData['end']
          };
          messages.push(message);
        }
      });
      this.chatMessagesSubject.next([...this.chatMessagesSubject.value, ...messages]);
    });
  }

  private newMessage(text: string): Message{
    const message = new Message()
    message.message = text;
    message.source= 'user';
    message.date = new Date();

    return message
  }
}
