export const environment = {
  production: true,
  app: 'prod',
  hmr: false,
  authUrl: 'https://api.bellabeat.io/auth-ms',
  authMsApi: 'https://api.bellabeat.io/mark-aut-ms',
  socialLoginUrl: 'https://uaa.bellabeat.com',
  mobileApp: 'https://well.bellabeat.com/discover',
  pdMobileApp: 'https://well.bellabeat.com/discover',
  resetPassword: 'https://coach.bellabeat.com/#/mpt/password-recover',
  buySubscription:
    'https://lead.bellabeat.com/pay?s=price_1IQCZFF118aqM8IJkcUY0VQ2&returnUrl=https%3A%2F%2Fcoach.bellabeat.com',
  personalizeQuiz: 'https://lead.bellabeat.com/registerQuiz/0',
  subscriptionManager: 'https://account.bellabeat.com/subscription',
  activityDataUrl: 'https://api.bellabeat.io/data-ms/api/user',
  meditationDataUrl: 'https://api.bellabeat.io/meditation-ms/api/meditation',
  hydrationDataUrl: 'https://api.bellabeat.io/aggregator-ms/api/hydration-aggregation-daily',
  leafApiUrl: 'https://api.bellabeat.io/you-ms',
  leafStatsApiUrl: 'https://api.bellabeat.io/stats-ms/api',
  customActivity: 'https://api.bellabeat.io/you-ms/custom-activity',
  customActivities: 'https://api.bellabeat.io/you-ms/user-custom-activity',
  userData: 'https://api.bellabeat.io/you-ms/user-data',
  firebaseContAdm: '/v1/content',
  firebaseFilters: '/v1/content/en/app-data/filters',
  firebaseContData: '/v1/data/content/categories',
  firebaseHydration: '/v1/data/hydration/intake/',
  firebaseExerciseCompleted: '/v1/data/content/completed/history/',
  firebaseMeditationCompleted: '/v0/data/meditation/completedExercises/',
  firebaseMeditationExercises: '/v1/meditation/en/exercises/',
  firebaseMeditationGoal: '/v0/data/meditation/goals/min_per_day',
  firestoreUserData: '/v0/data/user/',
  gtm: {
    id: 'GTM-WVP5CX8'
  },
  apple: {
    authorizationUrl: ' https://appleid.apple.com/auth/authorize',
    clientId: 'com.bellabeat.club',
    redirectUrl: 'https://coach.bellabeat.com',
  },
  facebook: {
    appId: '1660913714128630',
    clientId: 'web.app',
    appSecret: 'cb20677f077eb46f0b78be1b715f59af'
  },
  flags: {
    allowIpad: true
  },
  auth: {
    credentials: 'https://api.bellabeat.io/auth-ms/users',
  },
  redirects: {
    bapPaymentUrl: 'https://lead.bellabeat.com/pay-bap'
  },
  menuItems: [
    {name: 'Home', link: '/page/home'},
    {name: 'Workout', link: '/page/workout'},
    {name: 'Mind', link: '/page/mind'},
    {name: 'Nutrition', link: '/page/nutrition'},
    {name: 'Health', link: '/page/health'},
    {name: 'Beauty', link: '/page/beauty'},
  ],
  goals: {
    meditation: 10,
    hydration: 2,
    activity: 20,
    sleep: 8,
  },
  diary: {
    cycleLength: 28,
    periodDuration: 5,
  },
  cdnPath: 'https://d1rdw86ji6lelz.cloudfront.net',
  media: {
    video: {
      radio1x1: 'app/content/video/trans/1x1',
      ratio4x5: 'app/content/video/trans/4x5',
      ratio16x9: 'app/content/video/trans/16x9',
    },
    audio: {
      music: 'app/content/audio/standalone',
      content: 'app/content/audio/desc',
      static: 'app/data/audio',
    },
    images: 'app/content/img',
    icons: 'app/data/static/icons',
    pdf: 'app/data/pdf',
  },
  firebase: {
    // apiKey: 'AIzaSyDiI2WIIP21SqyH3QiOERyDv7mIS_efIwA',
    // authDomain: 'bellabeat-1118.firebaseapp.com',
    // databaseURL: 'bellabeat-1118.firebaseio.com',
    // projectId: 'bellabeat-1118',
    // storageBucket: 'bellabeat-1118.appspot.com',
    // messagingSenderId: '982287810668',
    // appId: '1:982287810668:web:d678fb5e82e532cd755849',
    // measurementId: 'G-MMW2YC4RHF'
    apiKey: 'AIzaSyDiI2WIIP21SqyH3QiOERyDv7mIS_efIwA',
    authDomain: 'bellabeat-1118.firebaseapp.com',
    databaseURL: 'https://bellabeat-1118.firebaseio.com',
    projectId: 'bellabeat-1118',
    storageBucket: 'bellabeat-1118.appspot.com',
    messagingSenderId: '982287810668',
    appId: '1:982287810668:web:459700da70ba59e4755849',
    measurementId: 'G-QP9S06FWR8',
  },
  matomoUrl: 'https://api.bellabeat.io/appana-ms/api/analytics/matomo',
  iconsUrl: 'https://d1rdw86ji6lelz.cloudfront.net/club/icons/',
  config: {
    workout: {
      intro: 10000
    }
  },
  myData: {
    activity: true,
    diary: true,
    hydration: true,
    mindfulness: true,
    sleep: true,
    readiness: true,
  },
  promotions: {
    promoCode: 'GLOW2024',
    promoStart: '2024-01-22T00:00:00',
    promoEnd: '2024-02-05T23:59:59',
  },
  forYou: {
    symptoms: true
  },
  bellabeatBranding: true,
};
