import { Component } from '@angular/core';
import {BehaviorSubject, Observable, of, switchMap, take} from "rxjs";
import {ChatService} from "../../../../chat-common/src/lib/services/chat.service";
import {ClientChatService} from "../../../../chat-common/src/lib/services/client-chat.service";
import {UserService} from "../../../../chat-common/src/lib/services/user.service";
import {ChatMessagesComponent} from "../../../../chat-common/src/lib/ui/chat/chat-messages/chat-messages.component";
import {ChatInputComponent} from "../../../../chat-common/src/lib/ui/chat/chat-input/chat-input.component";
import {PrivacyConsentComponent} from "../legal/privacy-constent/privacy-consent.component";
import {AsyncPipe, NgIf} from "@angular/common";
import {Message} from '../../../../chat-common/src/lib/models/message';

@Component({
  selector: 'app-chat',
  standalone: true,
  imports: [
    ChatMessagesComponent,
    ChatInputComponent,
    PrivacyConsentComponent,
    NgIf,
    AsyncPipe
  ],
  templateUrl: './chat.component.html',
  styleUrl: './chat.component.scss'
})
export class ChatComponent {
  showPrivacyConsent = true;

  messageCollection: Message[] = []
  messages$: Observable<Message[]> = new BehaviorSubject<Message[]>([]);
  constructor(private userService: UserService,
              private chatService: ChatService,
              private clientChatService: ClientChatService,
              private userChatService: ClientChatService) {
    this.showPrivacyConsent = !(+this.userService.user.drChatLicenceAgreementAcceptedVersion > 0);
    this.messages$ = this.clientChatService.chatMessages$;
    // this.clientChatService.getChat().then(chat => {
    //  chat.forEach(message => {
    //    console.log(message.data());
    //    this.messageCollection.push(message.data() as Message)
    //  })
    //   this.messages$.next( this.messageCollection.reverse())
    // }, error => {
    //   console.log(error);
    // })
  }

  onSendMessage(text: any) {
    // this.chatService.selectedChatId$.pipe(
    //   take(1)
    // ).subscribe(chatId => {
    //   this.chatService.sendMessage(chatId, text);
    // });
    this.clientChatService.send(text).then(response => {
      this.messageCollection.push(response[1] as Message)
      // this.messages$.next(this.messageCollection )
    });
  }

  onConsent($event: boolean) {
    this.userService.saveLicenceAgreement(1).then(response => {
      this.showPrivacyConsent = false;
    })
  }
}
