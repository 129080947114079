import {Component, EventEmitter, Output} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-privacy-consent',
  standalone: true,
  imports: [],
  templateUrl: './privacy-consent.component.html',
  styleUrl: './privacy-consent.component.scss'
})
export class PrivacyConsentComponent {

  @Output()
  onConsent: EventEmitter<boolean> = new EventEmitter()
  constructor(private router: Router) {
  }
  decline() {
    this.router.navigate(['/home']).then(() => console.log('User declined consent'));
  }

  accept() {
    this.onConsent.emit(true)
  }
}
