import { Component } from '@angular/core';
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    RouterLink
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {
  isInstallTutorialVisible = false;
  isPWAInstalled = false;

  constructor() {
    // Check if the app is running in standalone mode (added to home screen)
    this.checkPWAInstallation();
  }

  showInstallTutorial() {
    this.isInstallTutorialVisible = true;
  }

  hideInstallTutorial() {
    this.isInstallTutorialVisible = false;
  }

  private checkPWAInstallation() {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      this.isPWAInstalled = true;
    }
  }
}
