<section class="min-h-screen bg-gradient-to-br from-purple-100 via-pink-100 to-yellow-100">
  <div class="container min-h-screen p-4 md:p-10">
    <div class="flex min-h-screen items-center justify-center">
      <div class="w-full max-w-6xl">
        <div class="block overflow-hidden rounded-2xl bg-white shadow-xl">
          <div class="lg:flex">
            <!-- Left column container -->
            <div class="px-4 md:px-0 lg:w-6/12">
              <div class="md:mx-6 md:p-12">
                <!-- Logo -->
                <div class="text-center">
                  <h1 class="text-2xl font-bold text-gray-900">bellabeat</h1>
                  <h2 class="mt-4 text-xl font-semibold text-gray-900">Log in</h2>
                  <p class="mt-2 text-sm text-gray-600">
                    Please fill in your existing Bellabeat credentials.
                  </p>
                </div>

                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="mt-8 space-y-4">

                  <!-- Email Input -->
                  <div>
                    <label for="email" class="block text-neutral-800 dark:text-neutral-400 mb-1">Email</label>
                    <input
                      id="email"
                      type="email"
                      formControlName="email"
                      class="block w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[twe-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none text-neutral-800 dark:text-neutral-800 dark:placeholder:text-neutral-300 dark:peer-focus:text-primary [&:not([data-twe-input-placeholder-active])]:placeholder:opacity-0 focus:outline-none focus:ring-0"
                      placeholder="email@example.com"
                    />
                  </div>

                  <!-- Password Input -->
                  <div>
                    <label for="password" class="block text-neutral-800 dark:text-neutral-400 mb-1">Password</label>
                    <input
                      id="password"
                      type="password"
                      formControlName="password"
                      class="block w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[twe-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none text-neutral-800 dark:text-neutral-800 dark:placeholder:text-neutral-300 dark:peer-focus:text-primary [&:not([data-twe-input-placeholder-active])]:placeholder:opacity-0 focus:outline-none focus:ring-0"
                      placeholder="8+ characters"
                    />
                  </div>

                  <!-- Submit button -->
                  <div class="mb-12 pb-1 pt-1 text-center">
                    <button
                      type="submit"
                      [disabled]="loginForm.invalid || isLoading"
                      class="mb-3 inline-block w-full rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-lg transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                      style="background: linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);"
                    >
                      {{ isLoading ? 'Logging in...' : 'LOG IN' }}
                    </button>
                  </div>

                  <!-- Social login section -->
                  <div class="flex items-center justify-between pb-6">
                    <p class="mb-0 mr-2">Don't have an account?</p>
                    <button
                      type="button"
                      class="inline-block rounded border-2 border-danger px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-danger transition duration-150 ease-in-out hover:border-danger-600 hover:bg-neutral-500/10 hover:text-danger-600 focus:border-danger-600 focus:text-danger-600 focus:outline-none focus:ring-0 active:border-danger-700 active:text-danger-700"
                    >
                      Register
                    </button>
                  </div>
                </form>
                <div *ngIf="loginError" class="text-red-500 text-center mb-4">
                  {{loginError}}
                </div>
              </div>
            </div>

            <!-- Right column container -->
            <div
              class="flex items-center rounded-b-lg lg:w-6/12 lg:rounded-r-lg lg:rounded-bl-none"
              style="background: linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593)"
            >
              <div class="px-4 py-6 text-white md:mx-6 md:p-12">
                <h4 class="mb-6 text-xl font-semibold">
                  Welcome to Bellabeat
                </h4>
                <p class="text-sm">
                  Join our community of health-conscious individuals. Track your wellness journey,
                  connect with others, and discover insights about your body and mind.
                  Start your journey to better health today.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
