export class Message {
  id: number;
  message: string;
  source: 'user' | 'doctor' | 'whatsapp' | 'other';
  date: Date;
  chatVersion?: string = '1';
  application: string = 'web';
  end?: boolean = false;
  sessionId?: string;
  userId: string;
}
