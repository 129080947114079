import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomeComponent} from "./public/home/home.component";
import {LoginComponent} from "./public/login/login.component";
import {ChatComponent} from "./chat/chat.component";
import {authGuard} from '../../../chat-common/src/lib/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: ChatComponent,
    redirectTo: 'pages'
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'register',
    redirectTo: 'login'
  },
  {
    path: 'pages',
    component: ChatComponent,
    canActivate: [authGuard] // Apply the guard here
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
