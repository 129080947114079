import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {APP_ENVIRONMENT} from "../../../chat-common/src/lib/services/environment.service";
import {initializeApp, provideFirebaseApp} from "@angular/fire/app";
import {getFirestore, provideFirestore} from "@angular/fire/firestore";
import {getDatabase, provideDatabase} from "@angular/fire/database";
import {getAuth, provideAuth} from "@angular/fire/auth";
import {provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {ChatPublicModule} from "./public/chat-public.module";
import {environment} from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ChatPublicModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    { provide: APP_ENVIRONMENT, useValue: environment },

    provideFirebaseApp(() => initializeApp(environment.firebase)),  // Use environment config
    provideFirestore(() => getFirestore()),
    provideDatabase(() => getDatabase()),
    provideAuth(() => getAuth()),
    provideHttpClient(withInterceptorsFromDi()),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
