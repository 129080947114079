import { Injectable } from '@angular/core';
import {BehaviorSubject, map, Observable} from "rxjs";
import {Message} from "../models/message";
import {Chat} from "../models/chat";

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  private chats = new BehaviorSubject<Chat[]>([
    { id: 1, userId: 'userea',  userShort: 'John Doe', lastMessage: 'Hello! How are you?', unreadCount: 0, raw: {},   messages: []},
    { id: 2, userId: 'usereb', userShort: 'Jane Smith', lastMessage: 'See you tomorrow!', unreadCount: 2, raw: {}, messages: []},
    { id: 3, userId: 'userec', userShort: 'Team Chat', lastMessage: 'Meeting at 3 PM', unreadCount: 1, raw: {}, messages: []}
  ]);



  private messagesByChat = new Map<number, BehaviorSubject<Message[]>>();
  private selectedChatId = new BehaviorSubject<number>(0);

  chats$ = this.chats.asObservable();
  selectedChatId$ = this.selectedChatId.asObservable();

  selectedChat$: Observable<Chat> = this.selectedChatId$.pipe(
    map(chatId => this.chats.value.find(chat => chat.id === chatId)!),
  );


  constructor() {
    // Initialize messages for the first chat
    this.initializeChat(1, [
      { userId: 'fdjghn', id: 1, message: 'Hello! How are you?', source: 'other', date: new Date(), application: 'bot' },
      { userId: 'fdjghn', id: 2, message: 'I\'m doing great, thanks for asking!', source: 'user', date: new Date(), application: 'bot'}
    ]);
  }

  private initializeChat(chatId: number, initialMessages: Message[] = []): void {
    if (!this.messagesByChat.has(chatId)) {
      this.messagesByChat.set(chatId, new BehaviorSubject<Message[]>(initialMessages));
    }
  }

  getMessagesForChat(chatId: number): Observable<Message[]> {
    this.initializeChat(chatId);
    return this.messagesByChat.get(chatId)!.asObservable();
  }

  selectChat(chatId: number): void {
    this.selectedChatId.next(chatId);
    const updatedChats = this.chats.value.map(chat =>
      chat.id === chatId ? { ...chat, unreadCount: 0 } : chat
    );
    this.chats.next(updatedChats);
  }

  sendMessage(chatId: number, text: string): void {
    this.initializeChat(chatId);
    const messagesSubject = this.messagesByChat.get(chatId)!;
    const currentMessages = messagesSubject.value;

    const newMessage: Message = {
      userId: '',
      id: currentMessages.length + 1,
      message: text,
      source: 'user',
      date: new Date(),
      application: 'web'
    };

    messagesSubject.next([...currentMessages, newMessage]);

    const updatedChats = this.chats.value.map(chat =>
      chat.id === chatId ? { ...chat, lastMessage: text } : chat
    );
    this.chats.next(updatedChats);
  }
}
