import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PublicModule} from "../../../../chat-common/src/lib/ui/public/public.module";
import {LoginComponent} from "./login/login.component";





@NgModule({
  declarations: [
    LoginComponent
  ],
  imports: [
    CommonModule,
    PublicModule
  ]
})
export class ChatPublicModule { }
