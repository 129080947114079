
@if (message.end !== true) {
  <div
    class="message-bubble relative"
    [class.message-received]="message.source !== 'user'"
    [class.message-sent]="message.source === 'user'"
    [class.message-whatsapp]="message.source === 'whatsapp'"
  >
    <div>{{ message.message }}</div>
    <div class="text-xs opacity-70 mt-1 text-right">
      {{ message.date | date:'shortTime' }}
    </div>
  </div>
} @else {
  <div class="message-end">{{message.message}}</div>
}


