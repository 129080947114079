import {Component, Input} from '@angular/core';
import { Message } from "../../../models/message";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-message-bubble',
  standalone: true,
  imports: [
    DatePipe
  ],
  templateUrl: './message-bubble.component.html',
  styleUrl: './message-bubble.component.scss'
})
export class MessageBubbleComponent {
  get message(): Message {
    return this._message;
  }
  @Input()
  set message(value: Message) {
    this._message = value;

    if(Object(this._message.date).hasOwnProperty('seconds')){
      // @ts-ignore
      this._message.date = new Date(this._message.date.seconds*10000);
    }
  }
  private _message!: Message;
}
