import {CanActivateFn, Router} from '@angular/router';
import {AuthService} from "../services/auth.service";
import {inject} from "@angular/core";
import {firstValueFrom} from "rxjs";

export const authGuard: CanActivateFn = async (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const refreshToken = localStorage.getItem(AuthService.BELLABEAT_REFRESH_TOKEN);
  const destinationRoute: string = state.url;
  if (!authService.isLoggedIn && refreshToken) {  //  Remove unnecessary !== true
    console.log("Should not be here")
    try {
      await firstValueFrom(authService.signWithRefreshToken(refreshToken)); // Convert to promise and await
      return true; //  Navigate after successful refresh
    } catch (error) {
      console.error(error);
      return router.createUrlTree(['public/login']);
    }
  } else if (!refreshToken) {
    console.log("Should not be here2")
    return router.createUrlTree(['home']); //  Return UrlTree for redirection
  } else if( !authService.isLoggedIn) {
    console.log("Should not be here3")
    return router.createUrlTree(['home']);
  }
  console.log("Should not be here4")
  return authService.isLoggedIn;
};
