export class User {
  // @ts-ignore
  token: string;
  // @ts-ignore
  username: string;
  // @ts-ignore
  id: string;
  // @ts-ignore
  email: string;
  // @ts-ignore
  subscribed: boolean;
  profile: any;
  settings: any;
  goals: any = {};

  isDoctor: boolean = false;
  personalSettings: any = {};
  personalUnits: any = {};
  partners: string[] = [];
  drChatLicenceAgreementAcceptedVersion: number;
}

export class UserProfile {
  dateOfBirth: string = '';
  heightInCm: number = 165;
  name: string = '';
  weightInKg: number = 60;
  weightMeasurementDate: string = '';
}

