<div class="min-h-screen bg-white">
  <div class="relative overflow-hidden">
    <div class="max-w-7xl mx-auto">
      <!-- Mobile Install Button - Only visible on mobile -->
      <div class="md:hidden fixed bottom-4 right-4 z-50">
        <button
          (click)="showInstallTutorial()"
          class="flex items-center space-x-2 px-4 py-2 rounded-full text-white shadow-lg"
          style="background: linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593)">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
          </svg>
          <span>Install App</span>
        </button>
      </div>

      <!-- Install Tutorial Dialog -->
      @if (isInstallTutorialVisible){
        <div class="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-50"
          [@slideIn]>
          <div class="bg-white rounded-lg shadow-xl max-w-md w-full">
            <div class="p-6">
              <div class="flex justify-between items-center mb-4">
                <h3 class="text-xl font-semibold text-gray-900">Install Our App</h3>
                <button
                  (click)="hideInstallTutorial()"
                  class="text-gray-400 hover:text-gray-500">
                  <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              <div class="space-y-4">
                <div class="flex items-center space-x-3">
                  <div class="flex-shrink-0 w-8 h-8 bg-gradient-to-r from-[#ee7724] to-[#dd3675] rounded-full flex items-center justify-center text-white">
                    1
                  </div>
                  <p>Tap the share button in your browser's menu</p>
                </div>

                <div class="flex items-center space-x-3">
                  <div class="flex-shrink-0 w-8 h-8 bg-gradient-to-r from-[#dd3675] to-[#b44593] rounded-full flex items-center justify-center text-white">
                    2
                  </div>
                  <p>Select 'Add to Home Screen' or 'Install App'</p>
                </div>

                <div class="flex items-center space-x-3">
                  <div class="flex-shrink-0 w-8 h-8 bg-gradient-to-r from-[#b44593] to-[#ee7724] rounded-full flex items-center justify-center text-white">
                    3
                  </div>
                  <p>Tap 'Add' or 'Install' in the prompt</p>
                </div>
              </div>

              <div class="mt-6">
                <button
                  (click)="hideInstallTutorial()"
                  class="w-full px-4 py-2 text-white rounded-md"
                  style="background: linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593)">
                  Got it!
                </button>
              </div>
            </div>
          </div>
        </div>
      }

      <div class="relative z-10 pb-8 sm:pb-16 md:pb-20 lg:w-full lg:pb-28 xl:pb-32">
        <main class="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
          <div class="sm:text-center lg:text-left">
            <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              <span class="block">Chat with Real Doctors</span>
              <span
                class="block bg-gradient-to-r from-[#ee7724] via-[#dd3675] to-[#b44593] bg-clip-text text-transparent">
                  Anytime, Anywhere
                </span>
            </h1>
            <p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
              Consult qualified medical practitioners for immediate medical advice. You can communicate with actual doctors around-the-clock through our private chat platform, which guarantees you get the care you require when you need it. Additionally, everything is safe and safeguarded to protect your privacy.
            </p>
            <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
              <div class="rounded-md shadow">
                <a
                  routerLink="/register"
                  class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white md:py-4 md:text-lg md:px-10"
                  style="background: linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593)">
                  Get Started
                </a>
              </div>
              <div class="mt-3 sm:mt-0 sm:ml-3">
                <a
                  routerLink="/login"
                  class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-[#dd3675] bg-white border-[#dd3675] hover:bg-pink-50 md:py-4 md:text-lg md:px-10">
                  Sign In
                </a>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
  <div class="py-12 bg-white">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="lg:text-center">
        <h2
          class="text-base font-semibold tracking-wide uppercase bg-gradient-to-r from-[#ee7724] via-[#dd3675] to-[#b44593] bg-clip-text text-transparent">
          Why Choose Us
        </h2>
        <p class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Healthcare Made Simple
        </p>
      </div>

      <div class="mt-10">
        <div class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
          <div class="relative">
            <div class="absolute flex items-center justify-center h-12 w-12 rounded-md text-white"
                 style="background: linear-gradient(to right, #ee7724, #d8363a)">
              <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
              </svg>
            </div>
            <p class="ml-16 text-lg leading-6 font-medium text-gray-900">24/7 Availability</p>
            <p class="mt-2 ml-16 text-base text-gray-500">
              Access medical professionals around the clock, whenever you need them.
            </p>
          </div>

          <div class="relative">
            <div class="absolute flex items-center justify-center h-12 w-12 rounded-md text-white"
                 style="background: linear-gradient(to right, #d8363a, #dd3675)">
              <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"/>
              </svg>
            </div>
            <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Secure & Private</p>
            <p class="mt-2 ml-16 text-base text-gray-500">
              A safe space where it's just you and a doctor.
            </p>
          </div>

          <div class="relative">
            <div class="absolute flex items-center justify-center h-12 w-12 rounded-md text-white"
                 style="background: linear-gradient(to right, #dd3675, #b44593)">
              <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M13 10V3L4 14h7v7l9-11h-7z"/>
              </svg>
            </div>
            <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Instant Response</p>
            <p class="mt-2 ml-16 text-base text-gray-500">
              Get quick responses from qualified medical professionals.
            </p>
          </div>

          <div class="relative">
            <div class="absolute flex items-center justify-center h-12 w-12 rounded-md text-white"
                 style="background: linear-gradient(to right, #b44593, #ee7724)">
              <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"/>
              </svg>
            </div>
            <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Licensed Doctors</p>
            <p class="mt-2 ml-16 text-base text-gray-500">
              Chat with verified and licensed healthcare professionals.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-gray-50">
    <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
      <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
        <span class="block">Ready to get started?</span>
        <span class="block bg-gradient-to-r from-[#ee7724] via-[#dd3675] to-[#b44593] bg-clip-text text-transparent">
            Start chatting with doctors today.
          </span>
      </h2>
      <div class="mt-8 flex lg:mt-0 lg:flex-shrink-0">
        <div class="inline-flex rounded-md shadow">
          <a
            routerLink="/register"
            class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white hover:opacity-90"
            style="background: linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593)">
            Get Started
          </a>
        </div>
        <div class="ml-3 inline-flex rounded-md shadow">
          <a
            routerLink="/login"
            class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-[#dd3675] bg-white hover:bg-pink-50">
            Sign In
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
