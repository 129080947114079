
  <app-privacy-consent *ngIf="showPrivacyConsent" (onConsent)="onConsent($event)"></app-privacy-consent>

  <div *ngIf="!showPrivacyConsent" class="flex flex-col h-screen">
    <!--  <app-chat-header [chat]="selectedChat$ | async" />-->
    <div class="flex flex-col min-h-screen grow">
      <app-chat-messages class="grow" [messages]="messages$ | async" />
    </div>
    <app-chat-input class="sticky bottom-0 z-50"  (send)="onSendMessage($event)" />

  </div>

