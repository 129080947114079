import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, from, Observable, of} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {User} from "../models/user";
import {APP_ENVIRONMENT} from "./environment.service";
import {Database, get, getDatabase, onValue, ref} from "@angular/fire/database";
import {doc, DocumentSnapshot, Firestore, getDoc, getFirestore, updateDoc} from "@angular/fire/firestore";
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  user: User;
  private paymentStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  paymentStatusOb$: Observable<any> = this.paymentStatus$.asObservable();
  constructor(
    private httpClient: HttpClient,
    @Inject(APP_ENVIRONMENT) private environment: any,
    private database: Database,
    private firestore: Firestore
  ) {

    this.user = JSON.parse(localStorage.getItem('user') || '{}');
    if (!this.user) {
      this.user = new User();
    } else {
      this.getSubscriptionInfo().subscribe();
    }

    // this.database = getDatabase()
    // this.firestore = getFirestore()
  }

  get isSubscribed(): boolean {
    return this.user.subscribed;
  }

  mergeArrays(firstArray: Array<any>, secondArray: Array<any>, goals: Array<any>): any[] {
    const finalArray: any[] = [];
    firstArray.forEach((item) => {
      const keys = Object.keys(item);
      keys.forEach((key) => {
        const values = item[key];
        values.forEach((value: any) => {
          const matchingKey = Object.keys(secondArray[0]).find(
            (secKey) => secKey === value
          );
          if (matchingKey) {
            const matchingValue = secondArray[0][matchingKey];
            finalArray.push({
              name: matchingValue.name,
              value: value,
              model: this.findMatch(value, goals)
            });
          }
        });
      });
    });
    return finalArray;
  }

  findMatch(needle: any, haystack: any): boolean {
    return haystack ? haystack.includes(needle) : false;
  }



  public setBasicUserInfo(id: string, name: string, email: string, isDoctor?: boolean): void {
    this.user.id = id;
    this.user.username = name;
    this.user.email = email;
    this.user.isDoctor = isDoctor;
    this.saveUserData();
  }


  public saveUserData(): void {
    localStorage.setItem('user', JSON.stringify(this.user));
  }

  // public saveProfile(profile: { profile: any; settings: any }): Observable<any> {
  //   this.user = Object.assign(this.user, profile);
  //   const profileRef = ref(this.database, `v0/data/users/${this.user.id}`)
  //   return from(update(profileRef, profile) );
  // }



  // public getUserProfile(): any {
  //   return onValue(doc(this.firestore, `v0/data/user/${this.user.id}/userInfo/profile`)), {
  //     onlyOnce: true
  //   }
  // }


  public getProfileData(): Observable<any> {
    const getProfile: any = of(
      this.getProfile(),
    );

    const settingsRef = ref(this.database, `v0/data/users/${this.user.id}/settings`);
    const getSettings: any = of(get(settingsRef).then(
      (snapshot) => {
        this.user.settings = snapshot.val();
        this.saveUserData();
      },
      (fail) => {
        console.log(fail.toString());
      },
    ),)
    const getSubscriptionInfo = this.getSubscriptionInfo();

    const getLicenceAgreement = this.getLicenceAgreement()

    return combineLatest([getProfile, getSettings, getSubscriptionInfo, getLicenceAgreement]);
  }

  getSubscriptionInfo(): Observable<any> {
    if (!this.user.id) {
      return of(null);
    }
    const subscriptionRef = ref(this.database, `v0/data/bellabeatContent/subscription/${this.user.id}`);

    return of(onValue(subscriptionRef,
        snapshot => {
        const subscriberDetails = snapshot;
                this.user.subscribed = subscriberDetails && (subscriberDetails as any).isActive;
                this.saveUserData();
              },
      (fail) => {
        console.log('Fail');
        console.log(fail.toString());
      }
    ))
    // return this.database
    //   .object(`v0/data/bellabeatContent/subscription/${this.user.id}`)
    //   .valueChanges()
    //   .pipe(
    //     tap(
    //       (snapshot) => {
    //         const subscriberDetails = snapshot;
    //         this.user.subscribed = subscriberDetails && (subscriberDetails as any).isActive;
    //         this.saveUserData();
    //       },
    //       (fail) => {
    //         console.log('Fail');
    //         console.log(fail.toString());
    //       }),
    //   );
  }

  private getProfile(): Promise<any> {
    const profileRef = ref(this.database, `v0/data/users/${this.user.id}/profile`);
    return get(profileRef)
    // this.database.database
    //   .ref(`v0/data/users/${this.user.id}/profile`)
    //   .once('value')
    //   .then(
    //     (snapshot) => {
    //       this.user.profile = snapshot.val();
    //       this.saveUserData();
    //     },
    //     (fail) => {
    //       console.log(fail.toString());
    //     },
    //   );
  }


  /**
   *  Fill user details and redirect to user subscription page
   */
  subscribe(): void {
    const domainName = window.location.hostname;
    window.location.href = this.environment.buySubscription + `&email=${this.user.email}&name=${this.user.username}&leadCode=${domainName}`;
  }


  saveCredentials(data: { email: string, name: string }): Observable<any> {
    const apiUrl = this.environment.auth.credentials + '/' + this.user.id;
    this.user.username = data.name;
    this.user.email = data.email;
    return this.httpClient.post(apiUrl, data);
  }

  getUserDetailsFromToken(token: string): Observable<any> {
    const apiUrl = this.environment.auth.credentials + '/user-identifiers?passwordResetToken=' + token;
    return this.httpClient.get(apiUrl);
  }

  updatePaymentStatus(value: any): any {
    this.paymentStatus$.next(value);
    this.saveUserData();
  }

  private getLicenceAgreement() {
    const docRef = doc(this.firestore, `v0/data/user/${this.user.id}/userInfo/permissions`);
    return from(getDoc(docRef)).pipe(
      tap((docSnapshot: DocumentSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          this.user.drChatLicenceAgreementAcceptedVersion = data['drChatLicenceAgreementAcceptedVersion'] ?? 0;
          this.saveUserData();
        } else {
          console.log('No such document!');
        }
      })
    );
  }

  public saveLicenceAgreement(version: number): Promise<void> {
    const docRef = doc(this.firestore, `v0/data/user/${this.user.id}/userInfo/permissions`);
    return updateDoc(docRef, { drChatLicenceAgreementAcceptedVersion: version });
  }
}
